<template>
  <div>
    <h1>Muuda</h1>
    <div class="mb-2">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-text-field
            v-model="title"
            label="Pealkiri"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            outlined
            label="Kindlustusliik"
            v-model="insurance_type"
            :items="insurance_types"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="recommendation_weight"
            label="Kaal soovituse arvutamisel"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <QueryBuilder :query="query"> </QueryBuilder>
    <v-btn
      v-show="true"
      dark
      depressed
      color="#3d9732"
      @click="save"
      class="mt-5"
    >
      Salvesta
    </v-btn>
  </div>
</template>

<script>
  import axios from "axios";
  import QueryBuilder from '../components/QueryBuilder/QueryBuilder';

  export default {
    name: "Conditions",
    components: {
      QueryBuilder
    },
    data() {
      return {
        condition_id: null,
        title: '',
        insurance_type: '',
        recommendation_weight: 0,
        query: {
          logicalOperator: 'AND',
          rules: [
            {
              "rule": "",
              "operator": "",
              "value": ""
            }
          ],
          groups: []
        },
        insurance_types: [
          {
            text: 'Kasko',
            value: 'casco'
          },
          {
            text: 'Liikluskindlustus',
            value: 'mtpl'
          },
          {
            text: 'Kodukindlustus',
            value: 'home'
          },
          {
            text: 'Reisikindlustus',
            value: 'travel'
          },
          {
            text: 'Tööandja ravikindlustus',
            value: 'tooandja_ravikindlustus'
          }
        ]
      };
    },
    mounted() {
      this.condition_id = this.$route.params.id;
      this.fetchCondition(this.condition_id);
    },
    methods: {
      fetchCondition: function (condition_id) {
        let url = axios.defaults.baseURL + '/api/condition/' + condition_id;
        axios.get(url).then((response) => {
          this.title = response.data.title;
          this.insurance_type = response.data.insurance_type;
          this.recommendation_weight = response.data.recommendation_weight;
          this.query = response.data.config;
          console.log(this.query);
        });
      },
      save: function () {
        let payload = {
          condition_id: this.condition_id,
          title: this.title,
          insurance_type: this.insurance_type,
          recommendation_weight:  this.recommendation_weight,
          config: this.query
        };
        let url = axios.defaults.baseURL + '/api/condition/' + this.condition_id;
        axios.post(url, payload).then((response) => {
          this.$router.push('/conditions');
        });
      }
    }
  }
</script>